import React, { useEffect, useState } from "react";

import { Radio, RadioGroup, FormControlLabel, Container } from "@mui/material";
import Feedback from "./Feeback";

const TrueFalseQuestion = ({
  existingQuestion: question,
  showResult,
  setAnswers,
  answers,
}) => {
  const [answeredCorrectly, setAnsweredCorrectly] = useState(false);
  const [correctAnswerId, setCorrectAnswerId] = useState(null);

  // Find the answer for the curent question from the answers array
  let selectedAnswerId;
  answers.forEach((answer) => {
    if (answer.questionId === question.id) {
      selectedAnswerId = answer?.answer;
    }
  });

  // Keep track of the correct answer and if the user answered correctly
  useEffect(() => {
    const currentAnswer = answers.find(
      (answer) => answer.questionId === question.id
    );
    if (!currentAnswer) return;

    setAnsweredCorrectly(currentAnswer?.isCorrect);
    setCorrectAnswerId(currentAnswer?.correctAnswer);
  }, [answers, question.id]);

  const trueAnswerId = question.answers.find(
    (answer) => answer?.text?.toLowerCase() === "true"
  )?.id;
  const falseAnswerId = question.answers.find(
    (answer) => answer?.text?.toLowerCase() === "false"
  )?.id;

  const getAnswerStyle = (answerId) => {
    if (!showResult || !selectedAnswerId || !correctAnswerId) return {};

    if (selectedAnswerId === answerId) {
      return answerId === correctAnswerId
        ? { border: "1px solid green" }
        : { border: "1px solid red" };
    }

    if (answerId === correctAnswerId) {
      return {
        backgroundColor: "#1976d2",
        marginTop: "10px",
        marginBottom: "10px",
        "& .MuiFormControlLabel-label.Mui-disabled": {
          color: "white",
        },
      };
    }

    return {};
  };

  return (
    <Container
      sx={{
        height: "450px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="trueFalseAnswer"
        defaultValue={
          selectedAnswerId
            ? selectedAnswerId === trueAnswerId
              ? "true"
              : "false"
            : ""
        }
        disabled={showResult}
      >
        <div style={{ marginBottom: "20px", fontSize: "32px" }}>
          {question.text}
        </div>
        <FormControlLabel
          value="true"
          control={<Radio />}
          label="True"
          disabled={showResult}
          sx={getAnswerStyle(trueAnswerId)}
          onClick={() => {
            if (showResult) return;

            const filteredAnswers = answers.filter(
              (answer) => answer.questionId !== question.id
            );
            setAnswers([
              ...filteredAnswers,
              {
                questionId: question.id,
                answer: trueAnswerId,
              },
            ]);
          }}
        />
        <FormControlLabel
          value="false"
          control={<Radio />}
          label="False"
          disabled={showResult}
          sx={getAnswerStyle(falseAnswerId)}
          onClick={() => {
            if (showResult) return;

            const filteredAnswers = answers.filter(
              (answer) => answer.questionId !== question.id
            );
            setAnswers([
              ...filteredAnswers,
              {
                questionId: question.id,
                answer: falseAnswerId,
              },
            ]);
          }}
        />
      </RadioGroup>

      {showResult && (
        <Feedback
          answeredCorrectly={answeredCorrectly}
          explanation={question.answer_explanation}
        />
      )}
    </Container>
  );
};

export default TrueFalseQuestion;
