import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import Paper from "@mui/material/Paper";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import TrueFalseQuestion from "../../components/Question/TrueFalseQuestion";
import MultipleChoiceQuestion from "../../components/Question/MultipleChoiceQuestion";
import SelectAllQuestion from "../../components/Question/SelectAllQuestion";

const QuizPage = () => {
  const [page, setPage] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [showLastPage, setShowLastPage] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState(null);
  const quizId = new URLSearchParams(window.location.search).get("q");
  const userKey = new URLSearchParams(window.location.search).get("u");
  const companyKey = new URLSearchParams(window.location.search).get("c");
  const token = JSON.parse(localStorage.getItem("user"));

  useEffect(async () => {
    const fetchQuestions = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${companyKey}/quiz/${quizId}/${userKey}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: token?.accessToken
              ? `Bearer ${token.accessToken}`
              : "",
          },
        }
      );
      const data = await response.json();
      setQuestions(data.questions);
    };

    if (!quizId || !userKey || !companyKey) {
      setError("Missing quiz parameters!");
      return;
    } else {
      setError(null);
    }

    try {
      await fetchQuestions();
    } catch (e) {
      setError(
        "Failed to retrieve quiz questions! Please contact your adminstrator."
      );
    }
  }, [quizId, userKey]);

  const submitAnswer = async (questionId) => {
    const answersForCurrentQuestion = answers.filter(
      (answer) => answer.questionId === questionId
    );

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/submitAnswer`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token?.accessToken
            ? `Bearer ${token.accessToken}`
            : "",
        },
        body: JSON.stringify({
          questionId,
          quizId,
          answers: answersForCurrentQuestion,
        }),
      }
    );
    const data = await response.json();

    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.questionId === questionId
          ? {
              ...answer,
              isCorrect: data.answeredCorrectly,
              correctAnswer: data.correctAnswer,
            }
          : answer
      )
    );
  };

  const submitQuiz = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/${companyKey}/submitQuiz/${userKey}/${quizId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token?.accessToken
            ? `Bearer ${token.accessToken}`
            : "",
        },
      }
    );

    const data = await response.json();
  };

  const handleNext = () => {
    const nextQuestion = questions[page + 1];
    const nextQuestionId = nextQuestion?.id;
    const nextAnswer = answers.find(
      (answer) => answer.questionId === nextQuestionId
    );
    const currentAnswer = answers.find(
      (answer) => answer.questionId === questions[page].id
    );

    // Check if the current answer has isCorrect property (meaning it was submitted)
    const wasCurrentAnswerSubmitted = "isCorrect" in (currentAnswer ?? {});
    const wasNextAnswerSubmitted = "isCorrect" in (nextAnswer ?? {});

    // If the current question was not answered at all, lets not move to the next question
    if (!currentAnswer) {
      return;
    }

    // If we were not showing the results and haven't submitted this answer yet,
    // lets show them and keep the page the same
    if (!showResult && !wasCurrentAnswerSubmitted) {
      submitAnswer(questions[page].id);
      setShowResult(true);
      return;
    }
    // IF we are on the last page
    if (page === questions.length - 1) {
      try {
        submitQuiz();
      } catch (error) {
        console.log("Error submitting quiz", error);
      }

      setShowLastPage(true);
      return;
    }

    // If we are showing the results and there is a submitted answer for the next question,
    // lets move to the results of the next question
    if (nextAnswer && wasNextAnswerSubmitted) {
      setPage(page + 1);
      setShowResult(true);
      return;
    }

    // If we are showing the results, lets move to the next question
    setShowResult(false);
    setPage(page + 1);
  };

  const handlePrevious = () => {
    // If we are on the last page, lets go back to the previous question
    if (showLastPage) {
      setShowLastPage(false);
      return;
    }

    // If we were not showing the results, lets move to the previous question's results
    if (page > 0) setPage(page - 1);
    setShowResult(true);
  };

  if (error) return <div>{error}</div>;
  if (!questions?.length) return <div>Loading...</div>;

  return (
    <div
      style={{
        display: "flex",
        height: isMobile ? "90vh" : "85vh",
        justifyContent: isMobile ? "flex-start" : "center",
        alignItems: isMobile ? "" : "center",
        // Offset the left margin from PageContainer since we are hiding the left nav
        marginLeft: "-200px",
      }}
    >
      <Paper
        elevation={isMobile ? 0 : 3}
        sx={
          isMobile
            ? {
                width: "100%",
                borderRadius: "15px",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: showLastPage ? "flex-start" : "center",
                paddingBottom: "40px",
              }
            : {
                width: "60vw",
                minHeight: "60vh",
                maxHeight: "80vh",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: showLastPage ? "flex-start" : "center",
                paddingTop: "25px",
                paddingBottom: "25px",
                overflow: "auto",
              }
        }
      >
        {showLastPage ? (
          <LastPage answers={answers} questions={questions} />
        ) : (
          questions.map((question, index) => {
            if (index === page) {
              return question?.type?.toLowerCase() === "truefalse" ? (
                <TrueFalseQuestion
                  existingQuestion={question}
                  showResult={showResult}
                  setAnswers={setAnswers}
                  answers={answers}
                  key={question.id}
                />
              ) : question?.type?.toLowerCase() === "multiplechoice" ? (
                <MultipleChoiceQuestion
                  existingQuestion={question}
                  showResult={showResult}
                  setAnswers={setAnswers}
                  answers={answers}
                  key={question.id}
                />
              ) : question?.type?.toLowerCase() === "selectall" ? (
                <SelectAllQuestion
                  existingQuestion={question}
                  showResult={showResult}
                  setAnswers={setAnswers}
                  answers={answers}
                  key={question.id}
                />
              ) : (
                <div key={question.id}>Question type not supported</div>
              );
            }
          })
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
            marginBottom: "10px",
            position: isMobile ? "fixed" : "static",
            bottom: isMobile ? 0 : "auto",
            left: isMobile ? 0 : "auto",
            right: isMobile ? 0 : "auto",
            width: isMobile ? "100%" : "auto",
            backgroundColor: isMobile ? "white" : "transparent",
            padding: isMobile ? "10px" : 0,
            boxShadow: isMobile ? "0 -2px 5px rgba(0,0,0,0.1)" : "none",
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: "100px" }}
            onClick={handlePrevious}
            disabled={page === 0}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{ width: "100px", marginLeft: "10px" }}
            onClick={handleNext}
            disabled={showLastPage}
          >
            {showLastPage ? "New" : showResult ? "Next" : "Submit"}
          </Button>
        </div>
      </Paper>
    </div>
  );
};

const LastPage = ({ answers, questions }) => {
  return (
    <Container
      sx={{
        ...(isMobile
          ? {
              minHeight: "650px",
              padding: "10px",
              overflowY: "auto",
            }
          : {
              display: "flex",
              flexDirection: "column",
              maxHeight: "calc(80vh - 100px)", // Adjust based on Paper's maxHeight and padding
              overflowY: "auto",
              paddingBottom: "25px",
            }),
      }}
    >
      <div style={isMobile ? {} : { marginLeft: "50px", marginRight: "50px" }}>
        {questions.map((question) => {
          const selectedAnswerObject = answers.find(
            (answer) => answer.questionId === question.id
          );
          const selectedAnswer = selectedAnswerObject?.answer;

          const selectedAnswerText = question.answers.find(
            (answer) => answer?.id === selectedAnswer
          )?.text;

          const correctAnswerId = answers.find(
            (answer) => answer.questionId === question.id
          )?.correctAnswer;

          let correctAnswerText = "";
          question.answers.forEach((answer) => {
            if (answer.id === correctAnswerId) {
              correctAnswerText = answer.text;
            }
          });
          const answeredCorrectly = correctAnswerId === selectedAnswer;

          return (
            <Accordion
              key={question.id}
              expanded={true}
              sx={
                answeredCorrectly
                  ? { backgroundColor: "rgba(0, 128, 0, 0.1)" }
                  : { backgroundColor: "rgba(255, 0, 0, 0.1)" }
              }
            >
              <AccordionSummary
                expandIcon={
                  answeredCorrectly ? (
                    <CheckIcon sx={{ color: "green" }} />
                  ) : (
                    <CloseIcon sx={{ color: "red" }} />
                  )
                }
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    transition: "none",
                    "&.Mui-expanded": {
                      transform: "none",
                    },
                  },
                }}
              >
                {question.text}
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Correct Answer:</span>{" "}
                    {correctAnswerText}
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Your Answer:</span>{" "}
                    <span
                      style={
                        answeredCorrectly
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {selectedAnswerText}
                    </span>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Container>
  );
};

export default QuizPage;
