import React, { useState } from 'react';
import { USER_STATE, USER_ACTION_TYPES } from '../../constants';
import { useImmerReducer } from 'use-immer';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS, CREATE_USER, UPDATE_USER, REMOVE_USER } from './userQueries';

import { 
  ListItem,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  TextField,
  Typography,
  Divider,
  Button,
  Stack,
  IconButton
 } from '@mui/material'
 import PersonIcon from '@mui/icons-material/Person';
 import DeleteIcon from "@mui/icons-material/Delete";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Grid } from '../../components/themedGrid';

const { FIRST_NAME, LAST_NAME, EMAIL, START_DATE, INITIALIZE_STATE } = USER_ACTION_TYPES;

const userReducer = (draft, action) => {
  const { type, value } = action;
  switch (type) {
    case FIRST_NAME:
      draft[FIRST_NAME] = value;
      return draft;
    case LAST_NAME:
      draft[LAST_NAME] = value;
      return draft;
    case EMAIL:
      draft[EMAIL] = value;
      return draft;
    case START_DATE:
      draft[START_DATE] = value;
      return draft;
    case INITIALIZE_STATE:
      return value;
    default:
      break;
  }
};

const UsersPage = (props) => {
  const [state, dispatch] = useImmerReducer(userReducer, USER_STATE);
  const [selectedUserId, setSelectedUserId] = useState();
  const { firstName, lastName, email, startDate } = state;

  const handleChange = (value, type) => {
    dispatch({ value, type });
  };
  const { data: queryData, loading: queryLoading, error: queryError, refetch } = useQuery(GET_USERS)

  const [createUser, { data, loading, error }] = useMutation(CREATE_USER)
  const [updateUser] = useMutation(UPDATE_USER)
  const [removeUser] = useMutation(REMOVE_USER)
  const handleSaveUser = async () => {
    if(selectedUserId) {
      await updateUser({
        variables: {
          userId: selectedUserId,
          firstName,
          lastName,
          email,
          startDate,
        }
      })
    } else {
      await createUser({
        variables: {
          firstName,
          lastName,
          email,
          startDate,
        }
      })
    }
   

    await refetch()
    dispatch({ type: INITIALIZE_STATE, value: USER_STATE })
  }

  const editUser = async (user) => {
    setSelectedUserId(user.id)
    dispatch({ 
      type: INITIALIZE_STATE, 
      value: {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        startDate: user.startDate,
      }
    })
  }

  const handleRemoveUser = async (userId) => {
    await removeUser({
      variables: {
        userId
      }
    })
    dispatch({ type: INITIALIZE_STATE, value: USER_STATE })
    await refetch()
  }

  const handleCancel = () => {
    setSelectedUserId()
    dispatch({ type: INITIALIZE_STATE, value: USER_STATE })
  }

  if (queryLoading) return <p>Loading...</p>;
  if (queryError) return <p>Error :</p>;
  const users = queryData.users?.map(u => {
    const labelId = `checkbox-list-label-${u}`;

    return (
      <ListItem 
        key={u.id} 
        disablePadding 
        onClick={() => editUser(u)}
        secondaryAction={
          <IconButton
            aria-label="delete"
            onClick={() => handleRemoveUser(u.id)}
          >
            <DeleteIcon />
          </IconButton>
        }
      >
        <ListItemButton>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText id={labelId} primary={`${u.firstName} ${u.lastName}`}/>
        </ListItemButton>
      </ListItem>
    );
  })

  return (
    <>
      <Grid container spacing={2} height='100vh'>
        <Grid item xs>
          <Grid container spacing={2} sx={{marginBottom: '3vh'}}>
            <Grid item xs={12}>
              <Typography variant='h5'>Create/Edit User</Typography>
            </Grid>
            
            <Grid item xs={6}>
              <TextField 
                fullWidth
                id="firstName" 
                label="First Name" 
                variant="outlined" 
                value={state.firstName}
                onChange={({ target }) => handleChange(target.value, FIRST_NAME)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                fullWidth
                id="lastName" 
                label="Last Name" 
                variant="outlined" 
                value={state.lastName}
                onChange={({ target }) => handleChange(target.value, LAST_NAME)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                fullWidth
                id="email" 
                label="Email" 
                variant="outlined" 
                value={state.email}
                onChange={({ target }) => handleChange(target.value, EMAIL)}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  
                  disableFuture
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={state.startDate}
                  onChange={(value) => handleChange(value, START_DATE)}
                  renderInput={(params) => <TextField {...params} error={false}/>}
                />
              </LocalizationProvider>
            </Grid>
            
          </Grid>
          <Stack direction="row" spacing={2} >
            <Button onClick={() => handleSaveUser()} variant="contained">Save</Button>
            <Button variant="contained" onClick={() => handleCancel()}>Cancel</Button>
          </Stack>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs>                          
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='h5'>Users</Typography>
            </Grid>
            <Grid item xs={12}>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {users}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UsersPage;
