import React from 'react';
import { useQuery } from '@apollo/client';

import { Chart as ChartJS, LinearScale, Tooltip, Legend, CategoryScale, PointElement, LineElement, BarElement } from "chart.js";
import { Line, Bar } from 'react-chartjs-2';
import { 
  Typography,
  Grid,
 } from '@mui/material'
 import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GET_COMPANY_DATA } from './dashboardQueries';
import { format, toZonedTime } from "date-fns-tz";

ChartJS.register(CategoryScale, LinearScale, Tooltip, Legend, PointElement, LineElement, BarElement);

const Dashboard = (props) => {
  const [grouping, setGrouping] = React.useState('month');
  // const [fromDate, setFromDate] = React.useState(startOfWeek(today));
  // const [labels, setLabels] = React.useState([]);
  const handleChange = (event, newGrouping) => {
    if(!newGrouping || grouping === newGrouping) return
    setGrouping(newGrouping);
  };
  
  const { loading, data, error } = useQuery(GET_COMPANY_DATA, {
    variables: {
      filters: {
        groupBy: grouping
      }
    }
  })

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const companyData = data?.getCompanyData?.quizData
  const labels = getLabels(grouping, companyData)
  const scoreData = getScoreChartData(labels, companyData)
  const participationData = getParticipationChartData(labels, companyData)
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h5'>Dashboard</Typography>
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup
            color="primary"
            value={grouping}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="day">Day</ToggleButton>
            <ToggleButton value="week">Week</ToggleButton>
            <ToggleButton value="month">Month</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={6}>
          <Line data={scoreData} />
        </Grid>
        <Grid item xs={6}>
          <Bar data={participationData} />
        </Grid>
      </Grid>
    </div>
  );
};

const getScoreChartData = (labels, data) => {
  if(data?.length === 0 || !data.length) return []

  const scoreData = data.map(d => d.averageScore * 100)

  const rateData = data.map(d => (d.quizzesStarted/d.numberOfQuizzes) * 100)
  return {
    labels,
    datasets: [
      {
        label: 'Average Scores',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: scoreData || [],
      },
      {
        label: 'Participation Rate',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(236, 152, 159,0.4)',
        borderColor: 'rgba(236, 152, 159,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(236, 152, 159,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(236, 152, 159,1)',
        pointHoverBorderColor: 'rgba(236, 152, 159,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: rateData,
      },
    ],
  };
}

const getParticipationChartData = (labels, data) => {
  if(data?.length === 0 || !data.length) return []

  const quizzesStartedData = data.map(d => d.quizzesStarted)
  const quizzesCompletedData = data.map(d => d.quizzesCompleted)
  const totalCountData = data.map(d => d.numberOfQuizzes)
  return {
    labels,
    datasets: [
      {
        label: 'Total',
        backgroundColor: 'rgba(236, 152, 159,0.2)',
        borderColor: 'rgba(236, 152, 159,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(236, 152, 159,0.4)',
        hoverBorderColor: 'rgba(236, 152, 159,1)',
        data: totalCountData,
      },
      {
        label: 'Started',
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: quizzesStartedData || [],
      },
      {
        label: 'Completed',
        backgroundColor: 'rgba(75, 192, 75,0.2)',
        borderColor: 'rgba(75, 192, 75,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75, 192, 75,0.4)',
        hoverBorderColor: 'rgba(75, 192, 75,1)',
        data: quizzesCompletedData || [],
      },
    ],
  };
}

const getParticipationRateChartData = (labels, data) => {
  if(data?.length === 0 || !data.length) return []

  const rateData = data.map(d => (d.quizzesStarted/d.numberOfQuizzes) * 100)
  return {
    labels,
    datasets: [
      {
        label: 'Participation Rate',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(236, 152, 159,0.4)',
        borderColor: 'rgba(236, 152, 159,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(236, 152, 159,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(236, 152, 159,1)',
        pointHoverBorderColor: 'rgba(236, 152, 159,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: rateData,
      },
    ],
  };
}

const getLabels = (grouping, data) => {
  let labels = []
  console.log(data)
  if(data?.length === 0 || !data.length) return []
  console.log('LABELS')
  if(grouping === 'day') {
    labels = data.map(d => {
       return formatInTimeZone(new Date(d.date), "EEE", "UTC")
    })
  } else if(grouping === 'week') {
    labels = data.map(d => {
       return formatInTimeZone(new Date(d.date), "MM/dd", "UTC")
    })
  } else if(grouping === 'month') {
    labels = data.map(d => {
      return formatInTimeZone(new Date(d.date), "MMMM", "UTC")
    })
  }
  console.log(labels)
  return labels
}

const formatInTimeZone = (date, fmt, tz) =>
  format(toZonedTime(date, tz), 
         fmt, 
         { timeZone: tz });

export default Dashboard;

